import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCategories } from '../../../core/api/categories';
import { FaIcons } from '../../../core/constants';
import {
  Category,
  FilterCategory,
  SelectedCategory
} from '../../../core/types/category';
import useClickOutside from '../../../hooks/useClickOutside';
import useProductsStore from '../../../store/products';
import FaIcon from '../../FaIcon';
import CategoryItem from './CategoryItem';
import styles from './styles.module.scss';

const CategoryFilter = () => {
  const { t } = useTranslation('translation');
  const {
    changeFilters,
    filters: { categories: savedCategories }
  } = useProductsStore();
  const [categories, setCategories] = useState<FilterCategory[]>([]);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const ref = useClickOutside(() => {
    setIsSearchActive(false);
  });

  const resetCategories = () => {
    changeFilters({ categories: [], selectedCategoryItems: [] });
  };

  const fetchCategories = async () => {
    try {
      const rawCategories = await getCategories();
      const formattedCategories = (rawCategories as Category[]).map(
        ({ codes, category: name, subcategories }) => ({
          codes,
          name,
          subCategories: subcategories.map(
            ({ codes, subcategory: name, sub_subcategories }) => ({
              codes,
              name,
              subCategories: sub_subcategories.map(
                ({ codes, sub_subcategory: name }) => ({
                  codes,
                  name
                })
              )
            })
          )
        })
      );

      setCategories(formattedCategories);
    } catch (error) {
      console.error('categories fetching error', error);
    }
  };

  const saveCategoriesBrunch = (categoriesSequence: SelectedCategory[]) => {
    changeFilters({ selectedCategoryItems: categoriesSequence });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className={styles.categoryFilter} ref={ref}>
      <div className={styles.categoryInputWrapper}>
        <input
          className={styles.categoryInput}
          placeholder={t('filters.categories-placeholder')}
          // onChange={(event) => searchCategory(event.target.value)}
          onClick={() => setIsSearchActive(!isSearchActive)}
        />
        {!!savedCategories.length && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              resetCategories();
            }}
            className={styles.resetButton}
          >
            RESET
          </button>
        )}
        <FaIcon
          faName={FaIcons.chevronDown}
          className={styles.categoryInputIcon}
        />
      </div>
      <ul>
        {!!categories.length &&
          categories.map((category) => {
            const key = category.codes.toString().split(' ').join('-');

            return (
              <CategoryItem
                key={key}
                category={category}
                saveCategoriesBrunch={(items) =>
                  saveCategoriesBrunch([...items])
                }
              />
            );
          })}
      </ul>
    </div>
  );
};

export default CategoryFilter;
