import { useEffect, useState } from 'react';

import { SelectedCategory } from '../../core/types/category';
import useProductsStore from '../../store/products';
import Breadcrumbs, { BreadcrumbItem } from '../Breadcrumbs/Breadcrumbs';

const FiltersBreadcrumbs = () => {
  const {
    filters: { selectedCategoryItems },
    changeFilters
  } = useProductsStore();
  const [items, setItems] = useState<BreadcrumbItem[]>([]);

  const changeCategory = (item: SelectedCategory) => {
    const newCodes = item.codes;
    const selectedItemIndex = selectedCategoryItems.findIndex(
      (category) => category.codes === item.codes
    );

    if (selectedItemIndex !== -1) {
      const newSelectedCategories = selectedCategoryItems.slice(
        0,
        selectedItemIndex + 1
      );

      changeFilters({
        categories: newCodes,
        selectedCategoryItems: newSelectedCategories
      });
    }
  };

  useEffect(() => {
    const formattedCategories = selectedCategoryItems.map((item, index) => {
      const isLast = index === selectedCategoryItems.length - 1;

      return {
        title: item.name,
        ...(isLast ? {} : { action: () => changeCategory(item) })
      };
    });
    setItems(formattedCategories);
  }, [selectedCategoryItems]);

  if (!items.length) return <></>;

  return <Breadcrumbs prefix="filters: " items={items} />;
};

export default FiltersBreadcrumbs;
