import classNames from 'classnames';
import { useNavigate } from 'react-router';

import styles from './styles.module.scss';

export type BreadcrumbItem = {
  title: string;
  action?: () => void;
  link?: string;
};

interface IBreadcrumbsProps {
  prefix?: string;
  items: BreadcrumbItem[];
}

const Breadcrumbs = ({ items, prefix }: IBreadcrumbsProps) => {
  const navigate = useNavigate();

  return (
    <section className={styles.breadcrumbs}>
      {prefix && <span className={styles.breadcrumbsPrefix}>{prefix}</span>}
      {items.map((item) => {
        const hasAction = item.link || item.action;

        return (
          <button
            key={item.link || item.title}
            type="button"
            onClick={() => {
              if (item.link) navigate(item.link);
              if (item.action) item.action();
            }}
            className={classNames(styles.breadcrumb, {
              [styles.withAction]: hasAction
            })}
          >
            {item.title}
          </button>
        );
      })}
    </section>
  );
};

export default Breadcrumbs;
