import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableLocales } from '../../core/constants';
import LanguageToggleItem from './LanguageToggleItem';
import styles from './styles.module.scss';

const DropdownTexts = {
  // [AvailableLocales.EN]: 'English',
  [AvailableLocales.FR]: 'Français',
  [AvailableLocales.NL]: 'Nederlands'
};

const LanguageToggle = ({
  dropdownView,
  light,
  className,
  handleChange,
  disabledLocales = []
}: {
  dropdownView?: boolean;
  light?: boolean;
  className?: string;
  handleChange?: (newLanguage: AvailableLocales) => void;
  disabledLocales?: AvailableLocales[];
}) => {
  const { i18n } = useTranslation();
  const [activeLocale, setActiveLocale] = useState<AvailableLocales>(
    (i18n.language as AvailableLocales) || AvailableLocales.NL
  );
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const changeLanguage = (newLng: AvailableLocales) => {
    if (handleChange) {
      handleChange(newLng);
    } else {
      i18n.changeLanguage(newLng);
    }
    setActiveLocale(newLng);
    setShowDropdown(false);
  };

  const handleToggleClick = (newLng: AvailableLocales) => {
    if (dropdownView) {
      if (showDropdown && newLng !== activeLocale) changeLanguage(newLng);

      setShowDropdown(!showDropdown);
    } else {
      changeLanguage(newLng);
    }
  };

  return (
    <div
      className={classNames(
        styles.languageToggleWrapper,
        {
          [styles.dropdownView]: dropdownView,
          [styles.light]: light
        },
        className
      )}
    >
      {Object.values(AvailableLocales).map((locale) => (
        <LanguageToggleItem
          key={locale}
          locale={locale}
          text={locale}
          activeLocale={activeLocale}
          onChange={handleToggleClick}
          disabled={disabledLocales?.includes(locale)}
        />
      ))}
      {showDropdown && (
        <div className={styles.toggleDropdown}>
          {Object.values(AvailableLocales).map((locale) => (
            <LanguageToggleItem
              key={locale}
              locale={locale}
              text={DropdownTexts[locale]}
              activeLocale={activeLocale}
              onChange={handleToggleClick}
              isDropdown
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageToggle;
